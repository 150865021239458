<template>
	<div class="man flex-col">
		<div class="man-top flex-col">
			<img class="image1" src=".././assets/images/logo.png" alt="">
			<div class="center flex-col">
				<div class="center-item flex-row" v-for="(item,index) in footer_nav " :key="index"> 
					<div class="text_a" @click.stop="toIndex(item)">{{item.name}}</div>
					<div class="flex-row" style="flex-wrap: wrap;width: 85%;">
						<span class="text_1" v-for="(item1,index1) in item._child" :key="index1" @click.stop="toIndex(item1)">{{item1.name}}</span>
					</div>
				</div>
			</div>
			<div class="bottom flex-row justify-between">
				<div class="bottom-left  flex-col">
					<div class="bottom-left-item flex-row align-start">
						<div class="bottom-left-item-a align-center">
							<img src=".././assets/images/phone.png" alt="">&nbsp;{{about.tel_name}}&nbsp;&nbsp;
						</div>
						<div class="bottom-left-item-b  flex-col">
							<span class="text_2" v-for="(item,index) in about.tel" :key="index">{{item}}</span>
						</div>
					</div>
					<div class="bottom-left-item flex-row align-start">
						<div class="bottom-left-item-a align-center">
							<img src=".././assets/images/email.png" alt="">&nbsp;{{about.fax_name}}&nbsp;&nbsp;
						</div>
						<div class="bottom-left-item-b flex-col">
							<span class="text_2" v-for="(item,index) in about.fax" :key="index">{{item}}</span>
						</div>
					</div>
				</div>
				<img src=".././assets/images/code.png" alt="" class="image2">
			</div>
		</div>
		<div class="man-bottom flex-col align-center">
			<span class="text_3">
				联系我们 | 招贤纳士 | 法律声明版权所有 天津宝涞精工集团 {{beiAn}}
			</span>
			<div class="image-3 flex-row justify-between">
				<img class="thumbnail" referrerpolicy="no-referrer" src=".././assets/images/icon.png" />
				<span class="text_4">津公网安备12011502000252号</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		body,icp
	} from "../util/api";
	export default {
		data() {
			return {
				footer_nav: [],
				about: {},
				beiAn:''
			}
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		watch: {
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		created() {
			this.getDate();
			this.getBei()
		},
		methods: {
			getBei(){
				let url = window.location.href
				console.log(url);
				icp({host:url}).then(res=>{
					console.log(res.data.icp,"icp");
					this.beiAn=res.data.icp
				})
				// if(url.indexOf('www.cnbaolai.com/cnbaolai.com')!=-1){
				// 	this.beiAn="津ICP备13005513号-1"
				// }else if(url.indexOf('www.cn-jinlai.com/cn-jinlai.com')!=-1){
				// 	this.beiAn="津ICP备13005513号-2"
				// }else if(url.indexOf('www.china-chentao.com/china-chentao.com')!=-1){
				// 	this.beiAn="津ICP备13005513号-3"
				// }else{
				// 	this.beiAn=""
				// }
				
			},
			getDate() {
				body({
					lang: this.lang
				}).then((res) => {
					// console.log(res);
					this.footer_nav = res.data.footer_nav;
					this.about = res.data.about;
				});
			},
			toIndex(v) {
				console.log(v);
				if (v.target_rule==1){
					this.$router.push("/");
				}else if (v.target_rule==2){
					this.$router.push("/aboutUs");
				}else if (v.target_rule==3){
					let id = v.param
					this.$router.push("/news/"+id);
				}else if (v.target_rule==4){
					let news_id = v.param
					this.$router.push("/newDetail/"+news_id);
				}else if (v.target_rule==5){
					let id = v.param
					console.log(id);
					this.$router.push("/product/"+id);
				}else if (v.target_rule==6){
					this.$router.push("/companies");
				}else if (v.target_rule==7){
					this.$router.push("/Investor");
				}else if (v.target_rule==8){
					this.$router.push("/invite");
				}else if (v.target_rule==9){
					this.$router.push("/contact");
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.man {
		width: 100%;

		&-top {
			width: 100%;
			padding: 40px 20px;
			padding-right: 20px;
			background-color: rgba(38, 41, 56, 1);

			.image1 {
				width: 90px;
				height: 28px;
			}

			.center {
				margin-top: 21px;

				.center-item {
					.text_a {
						font-size: 9px;
						font-family: Noto Sans S Chinese;
						font-weight: 500;
						color: #FFFFFF;
					}

					.text_1 {
						font-size: 9px;
						font-family: Microsoft Hei;
						font-weight: 400;
						color: rgba(255, 255, 255, .4);
						margin-left: 24px;
						margin-bottom: 15px;
					}
				}
			}

			.bottom {
				margin-top: 15px;

				.bottom-left {
					.bottom-left-item {
						line-height: 18px;

						&-a {
							font-size: 9px;
							font-family: Microsoft Hei;
							font-weight: 400;
							color: rgba(255, 255, 255, .4);

							>img {
								width: 12px;
								height: 10px;
								opacity: 0.6;
							}
						}

						&-b {
							.text_2 {
								font-size: 9px;
								font-family: Microsoft Hei;
								font-weight: 400;
								color: rgba(255, 255, 255, .4);
							}
						}
					}
				}

				.image2 {
					width:90px;
					height:90px;
					margin-right: 9px;
				}
			}
		}
		&-bottom{
			background-color: rgba(27, 30, 45, 1);
			width: 100%;
			padding: 15px 0;
			font-size: 9px;
			font-family: Microsoft Hei;
			font-weight: 400;
			color:rgba(255, 255, 255, .4);
			.text_3{
				padding:0 5px;
				text-align: center;
			}
			.image-3{
				margin-top: 9px;
				.thumbnail{
					width: 9px;
					height: 11px;
					margin-right: 9px;
				}
			}
			
		}
	}
</style>
