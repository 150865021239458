<template>
	<div>
		<div class="flex-align-justify box36 ">
			<div class="box_36 flex-row justify-between align-start">
				<div class="" style="">
					<img class="image_46" referrerpolicy="no-referrer" src=".././assets/images/logo.png" />
				</div>
				<div class="group_21 flex-col">
					<div class="flex-row align-start" v-for="item in footer_nav" :key="item.id">
						<span class="text_100" @click.stop="toIndex(item)">{{item.name}}</span>
						<div class="flex-row " style="flex-wrap: wrap; width: 84%;">
							<span class="text_101" v-for="item1 in item._child" :key="item1.id" @click.stop="toIndex(item1)">{{ item1.name }}</span>
						</div>
					</div>
				</div>
				<div class="group_22 flex-col justify-center align-center">
					<div class="text-wrapper_21 flex-row ">
						<div class="text_106 flex-row">
							<img class="image_47" referrerpolicy="no-referrer" src=".././assets/images/phone.png" />
							<span class="">{{about.tel_name}}：</span>
						</div>
						<div class="text_107 flex-col">
							<span class="" v-for="(item,index) in about.tel" :key="index">{{item}}</span>
						</div>
					</div>
					<div class="text-wrapper_21 flex-row ">
						<div class="text_106 flex-row ">
							<img class="image_47" referrerpolicy="no-referrer" src=".././assets/images/email.png" />
							<span class="">{{about.fax_name}}：</span>
						</div>
						<div class="text_107 flex-col">
							<span class="" v-for="(item,index) in about.fax" :key="index">{{item}}</span>
						</div>
					</div>
					<div class="image_49">
						<img src="../assets/images/code.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="section_17 flex-col align-center">
			<span class="text_127">
				联系我们 | 招贤纳士 | 法律声明版权所有 天津宝涞精工集团 {{beiAn}}
			</span>
			<div class="image-text_3 flex-row justify-between">
				<img class="thumbnail_2" referrerpolicy="no-referrer" src=".././assets/images/icon.png" />
				<span class="text-group_8">津公网安备12011502000252号</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		body,icp
	} from "../util/api";
	export default {
		data() {
			return {
				footer_nav: [],
				about: {},
				beiAn:''
			}
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		watch: {
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		created() {
			this.getDate();
			this.getBei()
		},
		methods: {
			getBei(){
				let url = window.location.href
				console.log(url);
				icp({host:url}).then(res=>{
					console.log(res.data.icp,"icp");
					this.beiAn=res.data.icp
				})
				// if(url.indexOf('www.cnbaolai.com/cnbaolai.com')!=-1){
				// 	this.beiAn="津ICP备13005513号-1"
				// }else if(url.indexOf('www.cn-jinlai.com/cn-jinlai.com')!=-1){
				// 	this.beiAn="津ICP备13005513号-2"
				// }else if(url.indexOf('www.china-chentao.com/china-chentao.com')!=-1){
				// 	this.beiAn="津ICP备13005513号-3"
				// }else{
				// 	this.beiAn=""
				// }
				
			},
			getDate() {
				body({
					lang: this.lang
				}).then((res) => {
					// console.log(res);
					this.footer_nav = res.data.footer_nav;
					this.about = res.data.about;
				});
			},
			toIndex(v) {
				console.log(v);
				if (v.target_rule==1){
					this.$router.push("/");
				}else if (v.target_rule==2){
					this.$router.push("/aboutUs");
				}else if (v.target_rule==3){
					let id = v.param
					this.$router.push("/news/"+id);
				}else if (v.target_rule==4){
					let news_id = v.param
					this.$router.push("/newDetail/"+news_id);
				}else if (v.target_rule==5){
					let id = v.param
					console.log(id);
					this.$router.push("/product/"+id);
				}else if (v.target_rule==6){
					this.$router.push("/companies");
				}else if (v.target_rule==7){
					this.$router.push("/Investor");
				}else if (v.target_rule==8){
					this.$router.push("/invite");
				}else if (v.target_rule==9){
					this.$router.push("/contact");
				}
			},
		}
	}
</script>

<style lang="css" scoped>
	.box36 {
		background-color: rgba(38, 41, 56, 1);
		width: 100%;
		margin-top: 123px;
		/* padding: 0 5vw; */
		width: 100vw;
	}

	.box_36 {
		background-color: rgba(38, 41, 56, 1);
		max-width: 1650px;
		/* padding: 0 5vw; */
		width: 90vw;
		padding: 100px 0;
		min-width: 1150px;
		/* margin: 124px 0 1px 0; */
	}

	.group_21 {
		width: 57%;
		/* height: 65%; */
		/* width: 1480px;
  height: 57px; */
		/* margin: 79px 0 0 220px; */
	}

	.image_46 {
		width: 180px;
		height: 56px;
		margin-top: 1px;
	}

	.text_100 {
		/* width: 95px;
		height: 23px; */
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, 1);
		font-size: 24px;
		font-family: NotoSansHans-Medium;
		font-weight: 500;
		text-align: left;
		white-space: nowrap;
		line-height: 24px;
		margin-right: 70px;
		margin-bottom: 15px;
		cursor: pointer;
	}

	.text_101 {
		/* width: 73px;
  height: 19px; */
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, .6);
		font-size: 18px;
		font-family: MicrosoftHei;
		font-weight: NaN;
		text-align: left;
		white-space: nowrap;
		margin-right: 57px;
		margin-bottom: 35px;
		cursor: pointer;
		/* line-height: 56000px; */
		/* margin: 0 0 0 70px; */
	}


	.image-wrapper_10 {
		background-color: rgba(51, 102, 255, 0);
		border-radius: 4px;
		height: 24px;
		width: 24px;
		/* margin: 1px 0 0 103px; */
	}

	.image_47 {
		width: 22px;
		height: 18px;
		margin: 3px 5px 0 1px;
		
	}

	.text-wrapper_21 {
		/* width: 174px;
		height: 49px; */
		/* margin: 015 0 0 11px; */
		/* margin-top: 15px; */
	}

	.text_106 {
		/* width: 174px;
		height: 19px; */
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, .6);
		font-size: 18px;
		font-family: MicrosoftHei;
		font-weight: NaN;
		text-align: left;
		white-space: nowrap;
		/* line-height: 56000px; */
	}

	.text_107 {
		/* width: 123px;
		height: 15px; */
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, .6);
		font-size: 18px;
		font-family: MicrosoftHei;
		font-weight: NaN;
		text-align: left;
		white-space: nowrap;
		/* line-height: 40px; */
		/* margin: 15px 0 0 51px; */
	}

	.text_107>span {
		margin-bottom: 5px;
	}

	.text_107>span:nth-last-child(1) {
		margin-bottom: 18px;
	}

	.text-wrapper_22 {
		width: 202px;
		height: 19px;
		margin-left: 729px;
	}

	.group_22 {
		/* width: 209px;
  height: 24px; */
		/* margin: 6px 0 0 1491px; */
	}

	.image-wrapper_11 {
		background-color: rgba(51, 102, 255, 0);
		border-radius: 4px;
		height: 24px;
		width: 24px;
	}


	.text_110 {
		/* width: 175px; */
		/* height: 19px; */
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, .6);
		font-size: 18px;
		font-family: MicrosoftHei;
		font-weight: NaN;
		text-align: left;
		white-space: nowrap;
		/* line-height: 56000px;
  margin-top: 3px; */
	}

	.text-wrapper_23 {
		width: 499px;
		height: 23px;
		margin: 17px 0 0 562px;
	}

	.text_111 {
		width: 95px;
		height: 23px;
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, 1);
		font-size: 24px;
		font-family: NotoSansHans-Medium;
		font-weight: 500;
		text-align: left;
		white-space: nowrap;
		line-height: 24px;
	}

	.group_23 {
		width: 1124px;
		height: 181px;
		margin: 12px 0 82px 562px;
	}

	.text-wrapper_24 {
		width: 95px;
		height: 146px;
		margin-top: 35px;
	}

	.text_115 {
		width: 95px;
		height: 24px;
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, 1);
		font-size: 24px;
		font-family: NotoSansHans-Medium;
		font-weight: 500;
		text-align: left;
		white-space: nowrap;
		line-height: 24px;
	}


	.image_49 {
		width: 181px;
		height: 181px;
		margin-top: 64px;
	}

	.image_49>img {
		width: 100%;
		height: 100%;
	}

	.section_17 {
		background-color: rgba(27, 30, 45, 1);
		/* width: 1920px; */
		height: 140px;
		margin-top: -1px;
	}

	.text_127 {
		width: 593px;
		height: 19px;
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, 1);
		font-size: 16px;
		font-family: MicrosoftHei;
		font-weight: NaN;
		text-align: center;
		white-space: nowrap;
		line-height: 16px;
		margin: 43px 0 0 0px;
	}

	.image-text_3 {
		width: 265px;
		height: 20px;
		margin: 23px 0 35px 0px;
	}

	.thumbnail_2 {
		width: 18px;
		height: 20px;
	}

	.text-group_8 {
		width: 237px;
		height: 17px;
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, 1);
		font-size: 16px;
		font-family: MicrosoftHei;
		font-weight: NaN;
		text-align: center;
		white-space: nowrap;
		line-height: 16px;
		margin-top: 1px;
	}
</style>