<template>
	<div style="width: 100vw" :class="scrollTop > 50 && startX > 600 ? 'bg000' : ''">
		<div class="group_a flex-col" :class="[
        startX > 600 ? '' : 'wid600',
        scrollTop > 50 && startX > 600 ? 'bg000' : '',
      ]" :style="[show == 1 ? 'width:77vw;' : '']" @mouseleave="(showIn1 = -1), (showIn = -1)">
			<!--  :class="startX > 1920?'':'narTop3r'" -->
			<div class="block_2 flex-row justify-between align-center"
				:style="scrollTop > 50 && startX > 600 ? 'margin-top:38px' : ''">
				<img class="image_1" referrerpolicy="no-referrer" v-if="startX > 600" :src="
            require('.././assets/images/' +
              (show != 1 ? 'logo.png' : 'logoa.png'))
          " />
				<img class="image_1" referrerpolicy="no-referrer" v-else src=".././assets/images/logoa.png" />
				<div class="right flex-row" v-if="startX > 600">
					<div class="text_2 flex-col justify-center align-center"  v-for="(item, index) in title" :key="index"
						:class="startX>1920?'fontSize22':'fontSize15'" :style="show == 1 ? 'color:#111;' : ''"
						@click="toIndex(item), showIndex(index + 1)" @mouseenter="showIn1 = index + 1"
						@mouseleave="showChild = -1">
						<span style="cursor: pointer;"
							:style=" type == index + 1? show != 1 ? 'color: rgba(255, 255, 255, 1);': 'color:#0390CF;': ''">{{ item.name }}</span>
						<div class="line" v-if="type == index + 1"
							:class="[scrollTop > 50 && startX > 600 ? 'marTop20' : '',startX>2880?'lineAct':'']"
							:style="show == 1 ? 'background: #0390CF;' : ''"></div>
							<!---->
						<div class="child flex-col align-center"  v-if="
								(showIn == index + 1 || showIn1 == index + 1) &&item._child.length > 0 ">
							<div class="sss"></div>
							<div @click.stop="showChild = index1,toIndex(item1)" @mouseenter="showChild = index1"
								class="child-item flex-align-justify" v-for="(item1, index1) in item._child"
								:key="index1" :class="showChild == index1 ? 'act' : ''">
								{{ item1.name }}
								<div class="child3" v-if="showChild == index1">
									<div @click.stop="showChild1 = index1,toProduct(item2)" @mouseenter="showChild1 = index2"
										class="child-item flex-align-justify" v-for="(item2, index2) in item1._child"
										:key="index2" :class="showChild1 == index2 ? 'aaa' : ''">
										{{ item2.name }}
									</div>
								</div>
							</div>
							
						</div>
					</div>
					<div class="text_9 flex-row justify-center align-center" @click.stop="toLang"
						:class="show == 1 ? 'text_10' : ''" :style="myBg">
						<span :style="show == 1 ? 'color::#111;' : ''">{{lang=="zh_ch"?'中':'Eng'}}</span>
						<img class="thumbnail_1" referrerpolicy="no-referrer" :src="
							require('.././assets/images/' +(show != 1 ? 'more.png' : 'morea.png'))" />
						<div class="lang-popup" :style="myOp" @click.stop="toChanging">
							{{lang=="zh_ch"?'Eng':'中'}}
						</div>
					</div>
				</div>
				<div @click.stop="popupShow = !popupShow" class="flex-row align-center" v-else>
					<div class="text_1 flex-col align-center" style="margin-right: 15px;"
						@click.stop="toChanging(), popupShow=false">
						{{lang=="zh_ch"?'Eng':'中'}}
					</div>
					<img src=".././assets/images/more-top.png" alt="" class="more-top" />
				</div>
			</div>
			<van-popup :value="popupShow" position="top" class="flex-col align-end" @click-overlay="popupShow = false">
				<span class="text_1 flex-col align-center justify-center" v-for="(item, index) in title" :key="index"
					@click="toIndex(item), showIndex(index + 1)">
					{{ item.name }}
				</span>
				
			</van-popup>
		</div>
	</div>
</template>
<script>
	import {
		body
	} from "../util/api";
	export default {
		data() {
			return {
				constants: {},
				title: [],
				// widList:[],
				showIn: -1,
				showIn1: -1,
				showChild: -1,
				showChild1: -1,
				popupShow: false,
				scrollTop: 0,
				showLang: false
				// lang:'zh_ch',//zh_ch 简体中文 en_gb 英文（全球）
			};
		},
		props: {
			type: {
				type: Number,
			},
			show: {
				type: Number,
			},
			startX: {
				type: Number,
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
			myBg() {
				return {
					'font-size': this.startX > 1920 ? ' 22px;' : '1.5rem;',
					'background-color': this.showLang? 'transparent;' : '#fff;',
				}
			},
			myOp() {
				return {
					bottom:!this.showLang?'3rem':'-2rem',
					opacity: this.showLang ? '1' : '0',
				}
			},
		},
		created() {
			this.getDate();
			console.log(this.type, "===", this.show);
			// this.$store.commit("GET_LANG",this.lang);
		},
		mounted() {
			
			window.addEventListener("scroll", this.handleScroll);
		},
		watch: {
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		methods: {
			getDate() {
				body({
					lang: this.lang
				}).then((res) => {
					// console.log(res);
					this.title = res.data.header_nav;
					setTimeout(()=>{
						// this.getWidth()
					},100)
					
				});
			},
			getWidth(index){
				let ida = "id"+index
				// console.log(parseInt($(ida).get(0).offsetWidth));
				setTimeout(()=>{
					// let wid = document.getElementById(ida)
					// console.log(wid.offsetWidth,ida)
					// if(wid!=null){
						// list[i]=wid.offsetWidth/2
						// this.$forceUpdate()
						// return wid.offsetWidth/2
					// }
				},100)
				
				// this.$forceUpdate()
			},
			toChanging(){
				console.log(this.lang);
				if(this.lang=='zh_ch'){
					this.$store.commit("GET_LANG",'en_gb');
				}else{
					this.$store.commit("GET_LANG",'zh_ch');
				}
				// this.getDate()
				this.toLang()
			},
			toLang() {
				// console.log(this.showLang);
				console.log(this.showLang);
				this.showLang=!this.showLang
				// if (this.showLang == 0) {
				// 	this.showLang = 2
				// } else {
				// 	this.showLang = 0
				// }
				// this.$forceUpdate(); //强制更新
			},
			handleScroll() {
				this.scrollTop = Math.floor(
					document.documentElement.scrollTop || document.body.scrollTop
				);
				// console.log(this.scrollTop);
			},
			toIndex(v) {
				if (v.target_rule==1){
					this.$router.push("/");
				}else if (v.target_rule==2){
					this.$router.push("/aboutUs");
				}else if (v.target_rule==3){
					let id = v.param
					this.$router.push("/news/"+id);
				}else if (v.target_rule==4){
					let news_id = v.param
					this.$router.push("/newDetail/"+news_id);
				}else if (v.target_rule==5){
					let id = v.param
					console.log(id);
					this.$router.push("/product/"+id);
				}else if (v.target_rule==6){
					let id = v.param
					if(id==''){
						id=6
					}
					console.log(id);
					this.$router.push("/companies/"+id);
				}else if (v.target_rule==7){
					this.$router.push("/Investor");
				}else if (v.target_rule==8){
					this.$router.push("/invite");
				}else if (v.target_rule==9){
					this.$router.push("/contact");
				}
			},
			toProduct(v) {
				let id = v.param
				this.$router.push("/product/"+id);
			},
			showIndex(i) {
				if (this.showIn != i) {
					this.showIn = i;
				} else {
					this.showIn = -1;
				}
			},
			showIndex1(i) {
				if (this.showChild != i) {
					this.showChild = i;
				} else {
					this.showChild = -1;
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	.group_a {	
		background-color: rgba(255, 255, 255, 0);
		// height: 162px;
		width: 100vw;
		// min-width: 1450px;
		// max-width: 2160px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1100;
		padding-bottom: 2rem;
	}

	.bg000 {
		background-color: rgba(30, 30, 30, 0.5);
	}

	.marTop20 {
		margin-top: 16px !important;
	}

	.group_a.wid600 {
		width: 100%;
		padding-bottom: 0px;
		margin-left: 0;
		left: 0;
		background-color: #fff;

		.block_2 {
			margin-top: 0px;
			display: flex;
			align-items: center;
			padding: 0 4rem;
			z-index: 10002;
			background-color: #fff;
			height: 41px;
			width: 100%;
			min-width: 300px;

			.image_1 {
				width: 90px;
				height: 28px;
			}

			.more-top {
				width: 18px;
				height: 18px;
			}
		}

		.van-popup {
			background-color: transparent;
			text-align: right;
			top: 41px;

			.text_1 {
				width: 29%;
				// padding:2px 8px;
				padding: 2rem 1rem;
				font-size: 12px;
				font-family: Noto Sans S Chinese;
				font-weight: 500;
				text-align: center;
				// align-items: flex-end;
				color: #000;
				background-color: #fff;
				// border-bottom: 6px solid #ffffff;
			}
		}
	}

	.block_2 {
		// width: 80vw;
		height: 56px;
		// margin: 53px 0 0 160px;
		max-width: 2400px;
		width: 95vw;
		min-width: 1150px;
		margin: 0 auto;
		margin-top: 2rem;
	}

	.image_1 {
		max-width: 180px;
		max-height: 56px;
		width: 15rem;
		height: 4.5rem;
	}

	.fontSize22 {
		font-size: 22px;
		margin-right: 50px;
	}

	.fontSize15 {
		font-size: 1.5rem;
		margin-right: 2.4rem;
	}

	.text_2 {
		// width: 88px;
		// height: 21px;
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, 0.6);
		//   font-size: 1.5rem;
		font-family: NotoSansHans-Medium;
		font-weight: 500;
		text-align: left;
		white-space: nowrap;
		line-height: 2rem;
		// margin: 0 20px;
		position: relative;
	}

	.line {
		width: 100%;
		height: 0.35rem;
		background: #ffffff;
		border-radius: 3px;
		// margin-top: 21px;
		position: absolute;
		bottom: -1rem;
	}
	.lineAct {
		height: 4px;
		bottom: -2px;
	}
	.child {
		// min-width: 192px;
		/* height: 64px; */
		border: 3px solid #0390cf;
		border-radius: 10px;
		position: absolute;
		// left: -116px;
		// left: -50%;
		top: 60px;
		z-index: 9;
		background-color: #fff;
	}

	.item {
		color: #111;
	}

	.sss {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 12px solid #0390cf;
		position: absolute;
		top: -12px;
		// left: 154px;
		left: 50%;
	}

	.child-item {
		// width: 390px;
		height: 64px;
		padding: 15px 40px;
		font-size: 20px;
		font-family: Noto Sans S Chinese;
		font-weight: 500;
		color: #111;
		cursor: pointer;
	}

	.child-item.act {
		background-color: rgba(3, 144, 207, 0.04);
		color: #0390cf;
	}
	.child-item.aaa {
		color: #0390cf;
	}
	.child3{
		position: absolute;
		top: 0;
		left: 100%;
		background-color: #fff;
		box-shadow: 0px 0px 10px 0px rgba(73,117,178,0.3);
		border-radius: 10px;
	}

	.text_9 {
		// width: 19px;
		//   height: 20px;
		// overflow-wrap: break-word;
		color: rgba(255, 255, 255, 1);
		//   font-size: 1.5rem;
		font-family: NotoSansHans-Medium;
		font-weight: 500;
		text-align: left;
		position: relative;
		padding: 5px 14px;
		cursor: pointer;
		// white-space: nowrap;
		//   padding-top: 8px;
		// line-height: 30px;
		// margin: 18px 0 0 151px;
	}

	.text_9>span {
		z-index: 1000;
	}

	.text_10 {
		padding-top: 10px;
	}

	.thumbnail_1 {
		max-width: 14px;
		max-height: 8px;
		width: .8rem;
		height: .5rem;
		//   margin-left: .6rem;
		margin-left: .6rem;
		z-index: 1000;
		position: relative;
		// margin: 24px 0 0 14px;
	}

	.lang-popup {
		background-color: #fff;
		font-family: NotoSansHans-Medium;
		font-weight: 500;
		text-align: left;
		z-index: 99;
		position: absolute;
		// bottom: 0;
		padding: 5px 14px;
		color: #333;
		border-radius: 5px;
		transition: all 0.4s;
		width: 100%;
	}

	.image-wrapper_1 {
		width: 42px;
		height: 6px;
		margin: 3px 0 44px 428px;
	}

	.image_2 {
		width: 42px;
		height: 6px;
	}

	.group_2 {
		background-color: rgba(248, 248, 248, 0);
		width: 1920px;
		height: 2px;
		margin-bottom: 916px;
	}
</style>